import styled from 'styled-components'

import { device } from '../../Styles/breakPoints'

export const Avatar = styled.div`
  margin: 3rem;
  @media ${device.lg} {
    margin: 3rem 3rem 3rem 0;
  }
  .gatsby-image-wrapper {
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(255 288 227 / 40%);
  }
`

export const Body = styled.div`
  width: 100%;
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 6%;
  margin-bottom: auto;
  @media ${device.lg} {
    justify-content: flex-start;
    text-align: left;
    flex-direction: row;
  }
`

export const Description = styled.p`
  text-align: inherit;
  margin: 2rem 0;
  max-width: 30rem;
`

export const Tagline = styled.h4`
  text-align: inherit;
  margin: 1rem 0;
`

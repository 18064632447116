import React, { ReactElement } from 'react'

import { Container } from './styles'

const Footer = (): ReactElement => (
  <Container>
    <p>Copyright &copy; {new Date().getFullYear()}. All rights reserved.</p>
  </Container>
)

export default Footer

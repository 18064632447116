import React, { ReactElement, ReactNode } from 'react'

import { ParallaxContent } from './styles'

interface Props {
  speed: number;
  offset: number;
  children: ReactNode;
  factor?: number;
}

const Content = ({ speed, offset, children, factor = 1 }: Props): ReactElement => (
  <ParallaxContent
    speed={speed}
    offset={offset}
    factor={factor}
  >
    {children}
  </ParallaxContent>
)

export default Content

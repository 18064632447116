import { graphql, useStaticQuery } from "gatsby"

const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "profile"}) {
        fluid(maxWidth: 512) {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        description
      }
    }
  `)

  return data.contentfulAsset
}

export default useSiteMetadata

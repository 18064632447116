import React, { ReactElement, SVGProps } from 'react'

import { UpDown, UpDownWide } from '../Styles/animations'
import { BGIcon, BGIconProps } from './styles'
import Divider from './Divider'
import Icons, { getColor, getIcon } from './BGIcons'

interface Icon extends BGIconProps {
  Svg?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
}

interface Props {
  speed?: number;
  offset: number;
  factor: number;
}

const IconElement = (props: Icon): ReactElement => (
  <BGIcon
    {...props}
  >
    {props.Svg({})}
  </BGIcon>
)

const iconMap = (icons: Icon[]) => (
  icons.map(
    (props, index) => <IconElement 
      key={index}
      color={props.color || getColor()}
      Svg={props.Svg || getIcon()}
      {...props}
    />
  )
)

const Deco = ({ offset, factor, speed = 0.1 }: Props): ReactElement => (
  <Divider speed={speed} offset={offset} factor={factor}>
    <UpDown>
      {iconMap(Icons.upDown)}
    </UpDown>
    <UpDownWide>
    {iconMap(Icons.upDownWide)}
    </UpDownWide>
    {iconMap(Icons.none)}
  </Divider>
)

export default Deco
import React from 'react';

import Content from '../../Elements/Content'
import Deco from '../../Elements/Deco'

interface Props {
  offset: number;
  factor: number;
}

const Profile = ({ offset, factor }: Props) => {

  return <div>
    <Deco
      offset={offset}
      factor={factor}
      speed={0.2}
    />
    <Content speed={0.4} offset={offset} factor={factor}>
      <h1>Hi, I&apos;m Josh Wentworth</h1>
      <h4>I make all kinds of stuff, poke around to learn more.</h4>
    </Content>
  </div>;
}

export default Profile;
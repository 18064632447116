import React, { ReactElement } from 'react'
import { BGParallax } from './styles'

type DividerProps = {
  speed: number;
  offset: number;
  children?: React.ReactNode;
  bg?: string;
  clipPath?: string;
  factor?: number;
}

const Divider = ({
  speed,
  offset,
  factor = 1,
  bg = ``,
  clipPath = ``,
  children = null,
}: DividerProps): ReactElement => (
  <BGParallax
    bg={bg}
    clipPath={clipPath}
    speed={speed}
    offset={offset}
    factor={factor}
  >
    {children}
  </BGParallax>
)

export default Divider

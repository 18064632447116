import styled from 'styled-components'
import { ParallaxLayer } from '@react-spring/parallax'

import { device } from '../Styles/breakPoints'
import { sizes } from '../components/Menu/styles'

export interface BGIconProps {
  width: string;
  left: string;
  top: string;
  hiddenMobile: boolean;
  color?: string;
}

export const BGIcon = styled.div`
  position: absolute;
  width: ${({ width }: BGIconProps) => width};
  height: auto;
  left: ${({ left }: BGIconProps) => left};
  top: ${({ top }: BGIconProps) => top};
  color: ${({ color }: BGIconProps) => color};
  display: ${({ hiddenMobile }: BGIconProps) => hiddenMobile ? 'none': `block`};
  @media ${device.lg} {
    display: block;
  }
  svg {
    path:not(:first-child) {
      fill: currentColor;
    }
    circle {
      fill: currentColor;
    }
  }

`
interface ParallaxProps {
  bg: string;
  clipPath: string;
}

export const BGParallax = styled(ParallaxLayer)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ bg }: ParallaxProps) => bg};
  background-color: ${({ bg }: ParallaxProps) => bg};
  clip-path: ${({ clipPath }: ParallaxProps) => clipPath};
`

export const ParallaxContent = styled(ParallaxLayer)`
  width: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 50;
  @media ${device.lg} {
    align-items: flex-start;
    margin-left: ${sizes.width};
  }
`

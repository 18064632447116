import React from 'react';
import Img from 'gatsby-image';

import useProfile from './useProfile'
import colors from '../../Styles/colors'
import Divider from '../../Elements/Divider'
import Content from '../../Elements/Content'
import Deco from '../../Elements/Deco'

import {
  Avatar,
  Body,
  Description,
  Tagline,
} from './styles';

interface Props {
  offset: number;
  factor: number;
}

const Profile = ({ offset, factor }: Props) => {

  const { fluid, description } = useProfile();

  return <div>
    <Divider
      bg={colors.gradient.extra}
      clipPath={`polygon(0 15%, 100% 25%, 100% 85%, 0 75%)`}
      speed={-0.2}
      offset={offset /* 1.1 */}
      factor={factor}
    />
    <Content speed={0.4} offset={offset + 0.2} factor={factor}>
      <Body>
        <Avatar>
          <Img fluid={ fluid } />
        </Avatar>
        <div>
          <Tagline>Developer | Engineer | Musician</Tagline>
          <Description>{description}</Description>
        </div>
      </Body>
    </Content>
    <Deco
      offset={offset}
      factor={factor}
    />
  </div>;
}

export default Profile;
import React, { ReactElement } from "react"

import Deco from '../../Elements/Deco'
import Divider from '../../Elements/Divider'
import Content from '../../Elements/Content'
import Footer from '../Footer'
import WaveSVG from '../../Assets/wave'

import { Wave, Animation, Container, Info } from './styles'

interface Props {
  factor?: number;
  offset: number;
}

const Contact = ({ factor = 1, offset }: Props): ReactElement => (
  <div>
    <Divider
      speed={0.2}
      offset={offset}
      factor={factor}
    >
      <Wave>
        <Animation>
          <WaveSVG />
        </Animation>
      </Wave>
    </Divider>
    <Content
      speed={0.4}
      offset={offset}
      factor={factor}
    >
      <Container>
        <Info>
          <h1>Get at me!</h1>
          <p>contact@joshwentworth.com</p>
          <p>510.898.8892</p>
        </Info>
        <Footer />
      </Container>
    </Content>
    <Deco 
      offset={offset}
      factor={factor}
      speed={0.4}
    />
  </div>

)

export default Contact

import styled from 'styled-components'

import { waveAnimation } from '../../Styles/animations'

export const Wave = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: matrix(1, 0, 0, -1, 0, 0);
`

export const Animation = styled.div`
  position: relative;
  height: 100%;
  svg {
    width: 100%;
    height: 40vh;
  }
  path {
    animation: ${ waveAnimation('20s') };
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`

import colors from '../Styles/colors'
import * as Icons from '../Assets/bgIcons/components'
import { SVGProps } from 'react'

const sizes = [
  '2rem',
  '3rem',
  '4rem',
  '6rem',
  '8rem',
  '10rem',
  '12rem',
  '14rem',
]

export const getIcon = (): (props: SVGProps<SVGSVGElement>) => JSX.Element => {
  const icons = Object.keys(Icons)
  const index = Math.floor(Math.random() * icons.length)
  return Icons[icons[index]]
}

export const getColor = (): string => {
  const iconColors = [
    colors.primary.light,
    colors.primary.mid,
    colors.secondary.main,
    colors.secondary.dark,
    colors.grey.mid,
  ]
  const index = Math.floor(Math.random() * iconColors.length)
  return iconColors[index]
}

const icons = {
  upDown: [{
    width: sizes[6],
    left: '10%',
    top: '20%',
    hiddenMobile: true,
  },{
    width: sizes[5],
    left: '70%',
    top: '65%',
    hiddenMobile: false,
  },{
    width: sizes[1],
    left: '60%',
    top: '15%',
    hiddenMobile: false,
  }],
  upDownWide: [{
    width: sizes[3],
    left: '80%',
    top: '10%',
    hiddenMobile: true,
  },{
    width: sizes[4],
    left: '87%',
    top: '60%',
    hiddenMobile: false,
  },{
    width: sizes[3],
    left: '70%',
    top: '90%',
    hiddenMobile: false,
  },{
    width: sizes[5],
    left: '30%',
    top: '65%',
    hiddenMobile: false,
  },{
    width: sizes[3],
    left: '20%',
    top: '0',
    hiddenMobile: false,
  },{
    width: sizes[0],
    left: '75%',
    top: '6%',
    hiddenMobile: false,
  },{
    width: sizes[3],
    left: '65%',
    top: '10%',
    hiddenMobile: true,
  }],
  none: [{
    width: sizes[6],
    left: '5%',
    top: '70%',
    hiddenMobile: true,
  },{
    width: sizes[3],
    left: '45%',
    top: '10%',
    hiddenMobile: true,
  },{
    width: sizes[0],
    left: '4%',
    top: '20%',
    hiddenMobile: false,
  },{
    width: sizes[3],
    left: '50%',
    top: '66%',
    hiddenMobile: false,
  },{
    width: sizes[0],
    left: '95%',
    top: '90%',
    hiddenMobile: false,
  },{
    width: sizes[5],
    left: '40%',
    top: '80%',
    hiddenMobile: true,
  },{
    width: sizes[1],
    left: '25%',
    top: '5%',
    hiddenMobile: false,
  },{
    width: sizes[4],
    left: '85%',
    top: '5%',
    hiddenMobile: false,
  },{
    width: sizes[7],
    left: '5%',
    top: '90%',
    hiddenMobile: true,
  },{
    width: sizes[0],
    left: '10%',
    top: '10%',
    hiddenMobile: true,
  },{
    width: sizes[4],
    left: '40%',
    top: '20%',
    hiddenMobile: false,
  },{
    width: sizes[4],
    left: '2%',
    top: '50%',
    hiddenMobile: false,
  },{
    width: sizes[0],
    left: '90%',
    top: '40%',
    hiddenMobile: false,
  }],
}

export default icons

import React from 'react'
import { Parallax } from '@react-spring/parallax'

import Profile from '../components/Profile'
import Contact from '../components/Contact'
import Hero from '../components/Hero'

const RootIndex = () =>
  <Parallax pages={3} >
    <Hero 
      offset={0}
      factor={1}
    />
    <Profile 
      offset={1}
      factor={1}
    />
    <Contact
      offset={2}
      factor={1}
    />
  </Parallax>

export default RootIndex;
